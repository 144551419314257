 export const Method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const ApiEndpoint = {
  AUTH_SERVICE: '/api/v1/auth',
  REGISTER_SERVICE: '/api/v1/auth/register',
  SUBJECT_TOPIC:'/api/v1/subject-topics',
  READ_SYLLABUS:'/api/v1/syllabuses',
  GET_LANGUAGES:'/api/v1/languages',
  MCQ_QUESTION:'/api/v1/mcq',
  SUBJECT:'/api/v1/subjects',
  GROUPS:'/api/v1/subject-groups',
  QUESTIONS:'/api/v1/mcq' ,
  UNLIMITEDQUESTIONS:'/api/v1/unlimitedquestionpool',
  QUESTION:'/api/v1/questions',
  GRADES:'/api/v1/subject-grades',
  IMAGE:'/api/v1/images',
  VIDEOS:'/api/v1/videos',
  MCQ_PAST_PAPER:'/api/v1/mcq-past-papers',
  MCQ_PAST_PAPER_QUESTIONS:'/api/v1/mcq-past-papers-questions/paper' ,
  UNLIMITED_QUESTION_REVIEWS:'/api/v1/review',
  USER:'/api/v1/users',
  USER_ACCOUNT_DELETE_REQUEST:'/api/v1/account',
  TEACHER_SUBCRIPTION:'/api/v1/teacher-subscriptions',
  QUESTION_PAPER_FILES:'/api/v1/question-paper-files',
  QUESTION_CATEGORIZE:'/api/v1/question-categorize',
};
