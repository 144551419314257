import { Button, Grid, Link, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { deleteUser } from '../../services/user/user';

function DeleteAccount() {
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');

    const handleDeleteAccount = async() => {
        if (name === '') {
            alert('Please enter your username');
            return;
        }
        if (comment === '') {
            alert('Please enter your comment');
            return;
        }
        try{
            await deleteUser(name, comment);
            alert('Account delete request sent successfully');
        }catch(e){
            alert('Please try again later');
        }
    }

  return (
    <Grid container spacing = {2} style={{padding: 20}}>
        <Grid item xs = {12}>
            <Typography variant = "h4">Delete Account</Typography>
        </Grid>
        <Grid item xs = {12}><Typography variant = "body1">Are you sure you want to delete your account in Papere App? If you have anything to say before delete your account, please comment bellow.</Typography></Grid>
        <Grid item xs = {12}><Typography variant = "body1">Your account will be deleted after 7 days of this request.</Typography></Grid>
        <Grid item xs = {12}><Link variant = "body1" href='https://play.google.com/store/apps/details?id=lk.papere'>Link to Papere App in Goole Play</Link></Grid>

        <Grid item xs = {12}>
        <TextField id = "inputusername" label = "Username" variant = "outlined" value={name} onChange={e => setName(e.target.value)}/>
        </Grid>
        <Grid item xs = {12}>
        <TextField id = "inputcomment" label = "Comment" variant = "outlined" multiline value={comment} onChange={e => setComment(e.target.value)}/>
        </Grid>
        <Grid item xs = {12}>
            <Button variant = "contained" color = "primary" onClick={handleDeleteAccount}>Delete Account</Button>
        </Grid>
    </Grid>
  )
}

export default DeleteAccount