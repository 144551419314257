import { use } from 'i18next'
import React, { useState ,useEffect} from 'react'
import { useRoutes } from 'react-router-dom'
import Login from './components/login'
import AdminProtectedRoute from './routes/AdminProtectedRoute'
import SimpleLayout from './layouts/SimpleLayout'
import Dashboard from './components/Layout/Dashboard'
import SignUpForm from './components/Layout/SignUpForm'
import Teacher from './components/Layout/Teacher'
import LandingPage from './components/Layout/LandingPage'
import SyllabusGroup from './components/Layout/SyllabusGroup' 
import SyllabusesPage from './screens/syllabus/SyllabusesPage'
import SyllabusPage from './screens/syllabus/SyllabusPage'
import GradePage from './screens/syllabus/Grades/GradePage'
import SelectedSyllabusPage from './screens/syllabus/SelectedSyllabusPage'
import SubjectsPage from './screens/syllabus/SubjectsPage'
import LanguageSelector from './components/syllabuses/LanguageSelector'
//import GradeSelector from './components/syllabuses/GradeSelector'
import { AvailableLanguages } from './screens/syllabus/Languages';
import { getLanguages } from './services/subjects/languages'
import MultichoiceQuestion from './screens/question/MultichoiceQuestion'
import SubjectTopicsPage from './screens/syllabus/SubjectTopicsPage';
import QuestionsBank from './screens/syllabus/QuestionsBank'
import PrivacyPolicy from './screens/privacyPolicy/PrivacyPolicy'
import TermsAndConditions from './screens/termsAndConditions/TermsAndConditions'
import UnlimitedQuestionBank from './screens/syllabus/UnlimitedQuestionBank';
import PastPapers from './screens/pastPapers/PastPapers'
import QuestionsForPaper from './screens/pastPapers/QuestionsForPaper'
import AddQuestionaToPaper from './screens/pastPapers/AddQuestionaToPaper'
import QuizPage from './screens/dailyQuiz/QuizPage'
import QuizPaper from './screens/dailyQuiz/QuizPaper'
import RichEditor from './components/richTextEditor2/richEditor/RichEditor'
import AddUnlimitedQuestion from './screens/syllabus/AddUnlimitedQuestion'
import QuestionReviews from './screens/syllabus/QuestionReviews'
import OpenEditor from './screens/openEditor/OpenEditor'
import TyperProtectedRoute from './routes/TyperProtectedRoute'
import PdfQuestionPapers from './screens/pdfQuestionPapers/PdfQuestionPapers'
import ClassifyQuestionsScreen from './screens/classify-questions'
import DeleteAccount from './screens/deleteAccount/DeleteAccount'
// import TeachersTable from './screens/teachers/TeachersTable'
function Router() {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    // Fetch languages and update the state
    async function fetchLanguages() {
      try {
        const response = await getLanguages();  
        setLanguages(response.data);  
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    }

    fetchLanguages();
  }, []);
  const routes = useRoutes([
    {
      path: "/",
      element: <LandingPage/>,
      errorElement: <div>Not Found</div>
    },
    {
      path: "/login", element: <Login />,
      errorElement: <div>Not Found</div>
    },
    {
      path: "/delete-account", element: <DeleteAccount />,
      errorElement: <div>Not Found</div>
    },
    {
      path: "/signup", element: <SignUpForm />,
      errorElement: <div>Not Found</div>
    },
    {
      path: "/richeditor", element: <RichEditor />,
      errorElement: <div>Not Found</div>
    },
    {
      path: "/open-editor", element: <OpenEditor />,
      errorElement: <div>Not Found</div>
    },
    // {
    //   path: "/mcq/:subjectId_", element: <MultichoiceQuestion />,
    //   errorElement: <div>Not Found</div>
    // },
    // {
    //   path: "/mcq", element: <MultichoiceQuestion />,
    //   errorElement: <div>Not Found</div>
    // },
    {
      path: "/privacy-policy", element: <PrivacyPolicy />,
      errorElement: <div>Not Found</div>
    },
    {
      path: "/terms", element: <TermsAndConditions />,
      errorElement: <div>Not Found</div>
    },
    {
      path: "*", element: <div>Not Found</div>
    },
    {
      path: "/questions",
      element: (
        <AdminProtectedRoute >
          <SimpleLayout/>
        </AdminProtectedRoute>
        ),
        children: [
          {
            path: "mcq/:subjectId_", 
            element: <MultichoiceQuestion />,
            errorElement: <div>Not Found</div>
          },
          {
            path: "mcq",
            element: <MultichoiceQuestion />,
            errorElement: <div>Not Found</div>
          },
        ]
    },
    {
      path: "/dashboard",
      element: (
      <AdminProtectedRoute >
        <SimpleLayout/>
      </AdminProtectedRoute>
      ),
      children: [
        {
          path: "", 
          element: <Dashboard />,
          errorElement: <div>Not Found</div>
        },
        {
          path: "teacher",
          element: <Teacher />,
          errorElement: <div>Not Found</div>
        },
        {
          path: "syllabuses",
          element: (
            <AdminProtectedRoute >
              <SimpleLayout/>
            </AdminProtectedRoute>
            ),
          children: [
            {
              path: "",
              element: <SyllabusesPage />,
              errorElement: <div>Not Found</div>
            },

            {
              path: "selectedSyllabus",
              element: (
                <AdminProtectedRoute >
                  <SimpleLayout />
                </AdminProtectedRoute>
              ),
              children: [
                {
                  path: "",
                  element: <SelectedSyllabusPage />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "questionsBank/:subjectId",
                  element: <SimpleLayout />,
                  errorElement: <div>Not Found</div>,
                  children: [
                    {
                      path: "",
                      element: < QuestionsBank />,
                      errorElement: <div>Not Found</div>
                    },
                    {
                      path: "questions",
                      element: <SimpleLayout />,
                      children: [
                        {
                          path: "mcq/:subjectId_",
                          element: <MultichoiceQuestion />,
                          errorElement: <div>Not Found</div>
                        },
                        {
                          path: "mcq",
                          element: <MultichoiceQuestion />,
                          errorElement: <div>Not Found</div>
                        },
                      ]
                    }
                  ]
                },
                {
                  path: "ultimateQuestionsBank/:subjectId",
                  element: < UnlimitedQuestionBank />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "addUnlimitedQuestions/:subjectId",
                  element: < AddUnlimitedQuestion />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "pastPapers/:subjectId",
                  element: <PastPapers />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "questionsForPaper/:subjectId/:paperId",
                  element: <QuestionsForPaper />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "subjectTopics",
                  element: < SubjectTopicsPage />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "addQuestion/:subjectId/:paperId",
                  element: < AddQuestionaToPaper />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "reviews/:subjectId/:questionId",
                  element: <QuestionReviews />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "quizPage/:subjectId",
                  element: <QuizPage />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "quizPaper/:subjectId/:quizId",
                  element: <QuizPaper />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "subject/:subjectId/question-papers",
                  element: <PdfQuestionPapers />,
                  errorElement: <div>Not Found</div>
                },
                {
                  path: "subject/:subjectId/calasify-questions",
                  element: <ClassifyQuestionsScreen />,
                  errorElement: <div>Not Found</div>
                },
                // {
                //   path: "teachers",
                //   element: < TeachersTable />,
                //   errorElement: <div>Not Found</div>
                // },
              ]
            },
            {
              path: "syllabus",
              element: <SyllabusPage AvailableLanguages={languages} />,
              errorElement: <div>Not Found</div>
            },
            {
              path: "*", element: <div>Not Found</div>
            },
          ],
        },
      ]
    },
    {
      path: "/typerDashboard",
      element: (
      <TyperProtectedRoute >
        <SimpleLayout/>
      </TyperProtectedRoute>
      ),
      children: [
        {
          path: "", 
          element: <Dashboard />,
          errorElement: <div>Not Found</div>
        },
        {
          path: "syllabuses",
          element: <TyperProtectedRoute >
            <SimpleLayout />
          </TyperProtectedRoute>,
          errorElement: <div>Not Found</div>,
          children: [
            {
              path: "", 
              element: <SyllabusesPage />,
              errorElement: <div>Not Found</div>,
            },
            {
              path: "selectedSyllabus", 
              element: <TyperProtectedRoute >
              <SimpleLayout />
            </TyperProtectedRoute>,
              errorElement: <div>Not Found</div>,
              children: [
                {
                  path: "", 
                  element: <SelectedSyllabusPage />,
                  errorElement: <div>Not Found</div>
                },

                {
                  path: "questionsBank/:subjectId", 
                  element: <SimpleLayout />,
                  errorElement: <div>Not Found</div>,
                  children: [
                    {
                      path : "",
                      element: <QuestionsBank />,
                      errorElement: <div>Not Found</div>,
                    },
                    {
                      path: "questions",
                      element: <SimpleLayout />,
                      children: [
                        {
                          path: "mcq/:subjectId_",
                          element: <MultichoiceQuestion />,
                          errorElement: <div>Not Found</div>
                        },
                        {
                          path: "mcq",
                          element: <MultichoiceQuestion />,
                          errorElement: <div>Not Found</div>
                        },
                      ]
                    }
                  ]
                },

              ]
            },
          ]
        },
        {
          path: "*", element: <div>Not Found</div>
        },
      ],
    },
    // {
    //   path: "/questions",
    //   element: (
    //     <TyperProtectedRoute >
    //       <SimpleLayout/>
    //     </TyperProtectedRoute>
    //     ),
    //     children: [
    //       {
    //         path: "mcq/:subjectId_", 
    //         element: <MultichoiceQuestion />,
    //         errorElement: <div>Not Found</div>
    //       },
    //       {
    //         path: "mcq",
    //         element: <MultichoiceQuestion />,
    //         errorElement: <div>Not Found</div>
    //       },
    //     ]
    // },
  ])
  
  return routes;
}

export default Router

 